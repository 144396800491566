import { gql } from "@apollo/client";

export function countryFacets(filters) {
  const city = filters.regionSourceName
    ? `citySourceName {
      value
      count
  }`
    : "";

  const region = filters.countrySourceName
    ? `regionSourceName {
      value
      count
      ${city}
  }`
    : "";

  return `
  countrySourceName {
    value
    count
    ${region}
  }`;
}

export function getEccoTravelOffers(filters) {
  return gql`
  query GetEccoTravelOffers(
    $countryCode: Country!
    $languageCode: Language!
    $filters: Filters!
    $order: [Order!]
    $pagination: Pagination
  ) {
    search(
      countryCode: $countryCode
      languageCode: $languageCode
      filters: $filters
      order: $order
      pagination: $pagination
    ) {
      count
      totalTripCount
      facets {
        ${countryFacets(filters)}
        offerType {
          value
          count
          
        }
        departureCity {
          value
          count
          
        }
        departureDate {
          min
          max
          dailyPrices {
            value
            minPrice
          }
          monthly(limit:12){
            value
            year
            month
            count
            countOffers
        }
        }
        transportType {
          value
          count
        }
        maintenanceName {
          value
          count
        }
        period {
          value
          count
        }
        periodRange {
          max
          min
        }
        price{
          min
          max
          ranges(config: {start: 1000, end: 11000, gap: 2000}){
              count
              range{
                  min
                  max
              }
              min
              max
        }
    
      }
        propertyCategory {
          value
          count
        }
      }
      offers {
        id
        name
        score
        termsCount
        cities {
          sourceName
        }
        countries {
          sourceName
        }
        regions {
          sourceName
        }
        images {
          original
        }
        property {
          category
          sourceCategory
        }
        terms {
          id
          tripCode
          departureDate
          departureCity
          rooms {
            code
            description
            sourceCode
            sourceName
          }
          personsConfiguration {
            adults
            children
          }
          personPrices {
            age
            ageRange
            price
            type
          }
          maintenances {
            sourceCode
            sourceName
            name
          }
          period
          transportType
        }
        detectedSemanticCategories {
          accommodation
          activity
          boardType
          cuisine
          hotelAmenities
          hotelAttractions
          hotelType
          localization
          nature
          offerType
          other
          roomAmenities
          roomType
          skiing
          transportation
          tripAttractions
          tripType
        }
        semanticCategories {
          accommodation
          activity
          boardType
          cuisine
          hotelAmenities
          hotelAttractions
          hotelType
          localization
          nature
          offerType
          other
          roomAmenities
          roomType
          skiing
          transportation
          tripAttractions
          tripType
        }
      }
    }
  }
`;
}
