import { useQuery } from "@apollo/client";
import { getEccoTravelOffers } from "GraphQL/GetEccoTravelOffers";
import { getTourhubOffers } from "GraphQL/GetTourhubOffers";
import { getOffers } from "../GraphQL/GetOffers";
import { getPartnerOffers } from "../GraphQL/GetPartnerOffers";
import { hasPermission } from "../utils/handlePermissions";
import useAuth from "./useAuth";

const useGetOffers = (filters, sort, page, partnerSlug) => {
  let filtersFormatted = JSON.parse(JSON.stringify(filters));
  if (filtersFormatted.propertyCategory) {
    let tmp = [];
    filtersFormatted.propertyCategory.map((item) => {
      tmp = [...tmp, ...item.split("_")];
    });
    filtersFormatted.propertyCategory = tmp;
  }
  const auth = useAuth();

  const getQuery = () => {
    switch (partnerSlug) {
      case "ecco_travel":
      case "ecco_travel_nlp":
        return getEccoTravelOffers(filtersFormatted);
      case "tourhub":
        return getTourhubOffers(filtersFormatted);
      default:
        return hasPermission("has_semantic_response")
          ? getOffers(filtersFormatted)
          : getPartnerOffers(filtersFormatted);
    }
  };
  const { error, loading, data } = useQuery(getQuery(), {
    skip: !auth.isAuthenticated,
    variables: {
      countryCode: window.localStorage.getItem("countryCode"),
      languageCode: window.localStorage.getItem("languageCode"),
      filters: filtersFormatted,
      order: sort ? [sort] : ["SCORE_DESC"],
      pagination: { offset: ((page || 1) - 1) * 10, limit: 10 },
    },
    nextFetchPolicy: "cache-first",
  });

  return { error, loading, data };
};
export default useGetOffers;
