import { Box, Checkbox, Typography } from "@mui/material";
import _ from "lodash";

import styles from "./TransportType.module.scss";

import { TRANSPORT_TYPES } from "config/consts";
import { useTranslation } from "react-i18next";

const TransportType = ({ title, data, selected, onChange }) => {
  const { t } = useTranslation();
  const defaultChecked = data.length === 1;
  const currency = window.localStorage.getItem("currency");

  return (
    <Box mt={2} mb={4} mx={0}>
      <Typography as="h3" color="#000" fontFamily="Sharp Grotesk Medium" fontSize="16px" fontWeight="bold" mb={2}>
        {title}
      </Typography>

      <ul className={styles.list}>
        {data.map((item) => {
          const _name = _.snakeCase(item.value);
          const { count, countOffers, minPrice } = item;
          const key = `transport-type-${_name}`;
          const { icon: Icon } = _.find(TRANSPORT_TYPES, (transport) => transport.name === _name) || {};

          const isChecked =
            selected === item.value ||
            (Array.isArray(selected) && selected.indexOf(item.value) !== -1) ||
            defaultChecked;

          return (
            <li className={styles.item} key={key}>
              <Checkbox
                className={styles.checkbox}
                size="small"
                defaultChecked={isChecked}
                value={item.value}
                onChange={onChange}
                id={key}
                disabled={count === 0}
              />
              <label htmlFor={key} title={item.value} style={{ opacity: item.count ? 1 : 0.3 }}>
                {Icon ? <Icon className={styles.icon} /> : _name}
                {!!count && countOffers > 0 && <span className={styles.countOffers}>{countOffers}</span>}
                {!!count && count > 0 && <span className={`${styles.countOffers} ${styles.count}`}>{count}</span>}
                {!!minPrice && (
                  <span className={`${styles.countOffers} ${styles.minPrice}`}>
                    {`${t("offers_page.filters.from")} ${minPrice} ${currency}`}
                  </span>
                )}
              </label>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

export default TransportType;
