import { Check } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Divider, ListItemIcon, ListItemText } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { GET_FULL_PARTNER_INFO } from "GraphQL/GetFullPartnerInfo";
import { HOME_PATH, OFFERS_PATH } from "config/routes";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import amadeus from "images/amadeus.png";
import baboo from "images/baboo-logo.svg";
import qtravelBlue from "images/blueQtravel.png";
import coral from "images/coral.png";
import explore from "images/explore-logo.svg";
import prima_holiday from "images/prima-logo.png";
import qtravel from "images/qtravel.svg";
import rainbow from "images/rainbow.png";
import tanierejsowanie from "images/tanierejsowanie.png";
import tourhub from "images/tourhub_white.png";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GET_PARTNER_INFO } from "../../GraphQL/GetPartnerInfo";
import { graphQLApiService } from "../../GraphQL/GraphQLApiService";
import useAuth from "../../hooks/useAuth";
import atlas_tours from "../../images/atlas_tours.jpeg";
import best_reisen from "../../images/best_reisen.png";
import ecco_travel from "../../images/ecco_travel.png";
import frather from "../../images/frater.png";
import on_holiday from "../../images/on_holiday.png";
import volare_travel from "../../images/volare_travel.png";
import wygoda_travel from "../../images/wygoda_logo.png";
import { hasPermission } from "../../utils/handlePermissions";
import { Button } from "../Base";
import Lightbulb from "../Icons/Lightbulb";
import styles from "./Header.module.scss";

const Header = ({ skin, hideMenu }) => {
  const { t } = useTranslation();
  const partner = window.localStorage.getItem("partnerSlug");
  const { logout } = useAuth();
  const [partnerInfo, setPartnerInfo] = useState({});
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [OnBoarding_step_Dashboard, setOnBoarding_step_Dashboard] = useState(0);
  const [OnBoarding_step_Playground, setOnBoarding_step_Playground] = useState(0);

  const { width } = useWindowDimensions();

  const qtravelSkins = {
    blue: qtravelBlue,
    default: qtravel,
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logos = {
    qtravel: qtravelSkins[skin] || qtravel,
    baboo_travel: baboo,
    explore_co_uk: explore,
    rainbow: rainbow,
    coral_travel_pl: coral,
    coral_travel_lv: coral,
    coral_travel_lt: coral,
    coral_travel_ee: coral,
    prima_holiday: prima_holiday,
    tanierejsowanie: tanierejsowanie,
    amadeus_en: amadeus,
    amadeus_pl: amadeus,
    best_reisen: best_reisen,
    wygoda_travel: wygoda_travel,
    on_holiday: on_holiday,
    volare_travel: volare_travel,
    atlas_tours: atlas_tours,
    frather: frather,
    tourhub: tourhub,
    ecco_travel: ecco_travel,
  };

  useEffect(() => {
    if (process.env.REACT_APP_NLP_SETTINGS_ENABLED == "true") {
      graphQLApiService
        .query(GET_FULL_PARTNER_INFO, {
          countryCode: window.localStorage.getItem("countryCode"),
          languageCode: window.localStorage.getItem("languageCode"),
        })
        .then((data) => {
          setPartnerInfo(data.data.partnerInfo);
        })
        .catch((e) => console.log(e));
    } else {
      graphQLApiService
        .query(GET_PARTNER_INFO, {
          countryCode: window.localStorage.getItem("countryCode"),
          languageCode: window.localStorage.getItem("languageCode"),
        })
        .then((data) => {
          setPartnerInfo(data.data.partnerInfo);
        })
        .catch((e) => console.log(e));
    }
  }, []);
  window.addEventListener("storage", () => {
    setOnBoarding_step_Dashboard(sessionStorage.getItem("OnBoarding_step") == 4 ? 1 : 0);
  });
  window.addEventListener("storage", () => {
    setOnBoarding_step_Playground(sessionStorage.getItem("OnBoarding_step") == 5 ? 1 : 0);
  });

  return (
    <Box className={styles.headerWrapper}>
      <Box className={styles.logoWrapper}>
        {hasPermission("has_logo") && (
          <>
            <img src={logos[partner] || qtravel} className={styles.partnerLogo} />
            <span className={styles.poweredBy}>{t("main.powered_by")}</span>
          </>
        )}
        <Link to={HOME_PATH}>
          <img src={logos.qtravel} alt="Qtravel.ai" />
        </Link>
      </Box>
      <Box className={styles.buttonsWrapper}>
        {!hideMenu && (
          <>
            {partner &&
              width > 899 &&
              partner !== "demo_pl_classic_search" &&
              partner !== "demo_en_classic_search" &&
              partner !== "demo_en_full_text_search" &&
              partner !== "demo_pl_full_text_search" && (
                <>
                  {window.location.pathname.startsWith(OFFERS_PATH) && hasPermission("onboarding") && (
                    <Button
                      size="small"
                      variant="contained"
                      id="menu_playgorund_btn"
                      sx={{ mr: "20px", backgroundColor: "#fff", color: "#000" }}
                      className={styles.headerBtn}
                      onClick={() => {
                        localStorage.setItem("onboarding", true);
                        sessionStorage.setItem("OnBoarding_step", 0);
                        window.dispatchEvent(new Event("storage"));
                      }}
                    >
                      <Lightbulb className={styles.propertyIcon}> </Lightbulb> {t("onboarding.tutorial")}
                    </Button>
                  )}

                  {hasPermission("Playground") && (
                    <Button
                      size="small"
                      variant="contained"
                      id="menu_playgorund_btn"
                      sx={{
                        mr: "20px",
                        backgroundColor: "#fff",
                        color: "#000",
                        zIndex: 5000 * OnBoarding_step_Playground,
                        important: true,
                      }}
                      className={styles.headerBtn}
                      onClick={() => window.open(`${process.env.REACT_APP_DEVELOPER_URL}/?session=init`)}
                    >
                      {t("main.playground")}
                    </Button>
                  )}
                  {hasPermission("dashboard_button") && (
                    <Button
                      size="small"
                      id="menu_dashboard_btn"
                      variant="contained"
                      sx={{
                        mr: "20px",
                        backgroundColor: "#fff",
                        color: "#000",
                        zIndex: 5000 * OnBoarding_step_Dashboard,
                        important: true,
                      }}
                      className={styles.headerBtn}
                      onClick={() => window.open(`${process.env.REACT_APP_DASHBOARD_URL}/?session=init`)}
                    >
                      {t("main.dashboard")}
                    </Button>
                  )}
                </>
              )}
            <div>
              <Tooltip title={t("main.openSettings")}>
                <Button
                  size="large"
                  endIcon={<KeyboardArrowDownIcon />}
                  id="menu_logout_btn"
                  variant="outlined"
                  bordercolor="white"
                  color="white"
                  onClick={handleOpenUserMenu}
                >
                  {partnerInfo.name || "Partner"}
                </Button>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {process.env.REACT_APP_NLP_SETTINGS_ENABLED === "true" && (
                  <>
                    <MenuItem>
                      <ListItemText>{partnerInfo.slug}</ListItemText>
                    </MenuItem>
                    {partnerInfo.search_logic_operator && (
                      <>
                        <MenuItem>
                          <ListItemText>{partnerInfo.search_logic_operator} search logic operator</ListItemText>
                        </MenuItem>
                        <Divider />
                      </>
                    )}
                    {partnerInfo?.nlpConfig &&
                      Object.entries(partnerInfo.nlpConfig).map(([feature, config]) => (
                        <MenuItem key={feature}>
                          <ListItemIcon>{config.active ? <Check /> : null}</ListItemIcon>
                          {feature}
                        </MenuItem>
                      ))}
                    <Divider />
                  </>
                )}
                <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>{t("main.logout")}</MenuItem>
                {width < 900 && (
                  <>
                    <Divider />
                    <MenuItem onClick={() => window.open(`${process.env.REACT_APP_DEVELOPER_URL}/?session=init`)}>
                      {t("main.playground")}
                    </MenuItem>
                    <MenuItem onClick={() => window.open(`${process.env.REACT_APP_DASHBOARD_URL}/?session=init`)}>
                      {t("main.dashboard")}
                    </MenuItem>
                  </>
                )}
              </Menu>
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Header;
