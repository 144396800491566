import Rating from "@material-ui/lab/Rating";
import { Box, Checkbox, Typography } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import styles from "./HotelCategory.module.scss";

const HotelCategory = ({ data, selected, onChange }) => {
  const defaultChecked = data.length === 1;
  const { t } = useTranslation();

  const dataOrder = _.orderBy(data, "value", "desc");
  const currency = window.localStorage.getItem("currency");

  return (
    <Box borderRadius={4} mb={5}>
      <Typography as="h3" color="#000" fontFamily="Sharp Grotesk Medium" fontSize="16px" fontWeight="bold" mb={1}>
        {t("offers_page.filters.hotel_category")}
      </Typography>
      <ul className={styles.list}>
        {dataOrder.map(({ value, count, countOffers, minPrice }, index) => {
          const key = `hotel-category-${_.snakeCase(value)}-${index}`;

          return (
            <li className={styles.item} key={key}>
              <Box style={{ display: "flex" }}>
                <Checkbox
                  size="small"
                  value={value}
                  defaultChecked={(selected?.includes(value) || defaultChecked) ?? false}
                  className={styles.checkbox}
                  onChange={onChange}
                  id={key}
                  disabled={count === 0}
                />
                <Rating value={value / 10} precision={0.5} size="medium" readOnly />
              </Box>
              <label htmlFor={key} style={{ opacity: count ? 1 : 0.3, marginLeft: "20px" }}>
                {!!count && countOffers > 0 && <span className={styles.countOffers}>{countOffers}</span>}
                {!!count && count > 0 && <span className={`${styles.countOffers} ${styles.count}`}>{count}</span>}
                {!!minPrice && (
                  <span className={`${styles.countOffers} ${styles.minPrice}`}>
                    {t("offers_page.filters.from") + " " + minPrice + currency}
                  </span>
                )}
              </label>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

export default HotelCategory;
