import { Box, Tooltip, Typography } from "@mui/material";
import { Airplane, Calendar22, Children, ExpandMoreArrow, Info, Location, Man, OccupiedBed } from "components/Icons";
import AdultsOnly from "components/Icons/AdultsOnly";
import Family from "components/Icons/Family";
import humps from "lodash-humps";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import departureIcon from "../Icons/Departure.svg";
import Food from "../Icons/Food";
import Price from "../Icons/Price";
import Star from "../Icons/Star";
import styles from "./SearchSemanticModel.module.scss";

const SearchSemanticModel = ({ semanticResponse }) => {
  const { t } = useTranslation();
  const [OnBoarding_step, setOnBoarding_step] = useState(
    JSON.parse(sessionStorage.getItem("OnBoarding_step")) == 1 ? 1 : 0
  );
  const [isShowAll, setIsShowAll] = useState(false);

  const sourceSemanticCategories = [
    "accommodation",
    "activity",
    "board_type",
    "cuisine",
    "designation",
    "hotel_category",
    "hotel_amenities",
    "hotel_attractions",
    "hotel_type",
    "localization",
    "nature",
    "offer_type",
    "other",
    "room_amenities",
    "room_type",
    "skiing",
    "transportation",
    "trip_attractions",
    "trip_type",
    "price",
  ];

  const { calendar, location, people, price } = humps(semanticResponse || {});
  const period = calendar.period?.[0];

  const adults = people?.adults;
  const children = people?.children;

  const { continent: tripContinent, city: tripCities, country: tripCountries, region: tripRegions } = location || [];
  const allLocations = [...(tripCountries || []), ...(tripCities || []), ...(tripRegions || [])];
  const uniqueLocations = [...new Set(allLocations)];

  const periodText = period
    ? period.min !== undefined && period.max !== undefined
      ? period.min === period.max
        ? `${period.max}`
        : `${t("offers_page.filters.between")} ${period.min}-${period.max} `
      : period.min !== undefined
      ? `${t("offers_page.filters.from")} ${period.min}`
      : period.max !== undefined
      ? `${t("offers_page.filters.to")} ${period.max}`
      : null
    : null;

  const currency =
    localStorage.getItem("currency") !== "undefined" && localStorage.getItem("currency") === "zÅ"
      ? localStorage.getItem("currency")
      : "zł";

  const priceText =
    (price && price.max > 0) || price.min > 0
      ? price.min === price.max
        ? `${t("offers_page.filters.from")} ${price.min} ${currency}`
        : `${t("offers_page.filters.from")} ${price.min !== undefined ? price.min + currency : 0} ${
            price.max !== undefined ? `${t("offers_page.filters.to")} ${price.max + currency}` : ""
          }`
      : price.uniq
      ? `${price.uniq} ${currency}`
      : null;

  const departureDates = calendar.departureDate || [];

  const dateTexts = departureDates.map((dateRange) => {
    if (dateRange.max) {
      return dateRange.min === dateRange.max
        ? `${dateRange.min} → ${t("main.any")}`
        : `${dateRange.min || t("main.any")} → ${dateRange.max}`;
    } else {
      return `${dateRange.min} → ${t("main.any")}`;
    }
  });

  const adultsText =
    adults && adults.max > 0 ? (adults.min === adults.max ? adults.max : `${adults.min}-${adults.max}`) : null;

  const childrenText =
    children && children.max > 0
      ? children.min === children.max
        ? children.max
        : `${children.min}-${children.max}`
      : null;

  let whiteTags = [];
  let blueTags = [];

  const filteredMetaCategoriesNames = Object.keys(semanticResponse.meta_semantic_categories).filter((category) =>
    sourceSemanticCategories.includes(category)
  );

  const getIconForCategory = (categoryName, item = null) => {
    switch (categoryName) {
      case "hotel_category":
        return <Star className={styles.propertyIcon} />;
      case "accommodation":
        return <OccupiedBed className={styles.propertyIcon} />;
      case "board_type":
        return <Food className={styles.propertyIcon} />;
      case "transportation":
        return <Airplane className={styles.propertyIcon} />;
      case "price":
        return <Price />;
      case "designation":
        return item && item === "dla rodzin" ? (
          <Family style={{ height: "25px", width: "25px" }} className={styles.propertyIcon} />
        ) : (
          <AdultsOnly style={{ height: "25px", width: "25px" }} className={styles.propertyIcon} />
        );
      default:
        return null;
    }
  };

  const hotelCategoryModel = (item, from = "") => {
    return `${
      parseInt(item) > 40
        ? from + parseInt(item, 10) / 10 + t("offers_page.offers_list.hotel_category_50")
        : parseInt(item) === 10
        ? from + parseInt(item, 10) / 10 + t("offers_page.offers_list.hotel_category_10")
        : from + parseInt(item, 10) / 10 + t("offers_page.offers_list.hotel_category")
    }`;
  };

  const getTitle = (categoryName, item) => {
    switch (categoryName) {
      case "hotel_category":
        return item.min
          ? item.max
            ? `${hotelCategoryModel(item.min, t("offers_page.filters.from") + " ")} ${hotelCategoryModel(
                item.max,
                t("offers_page.filters.to") + " "
              )}`
            : hotelCategoryModel(item.min, t("offers_page.filters.from") + " ")
          : hotelCategoryModel(item);

      case "designation":
        return item;

      default:
        return `${t(`offers_page.offers_list.${categoryName}`)} - ${item}`.replaceAll("_", " ");
    }
  };

  filteredMetaCategoriesNames.forEach((categoryName) => {
    semanticResponse.meta_semantic_categories[categoryName].forEach((item) => {
      whiteTags.push({
        name: `${t(`offers_page.offers_list.${categoryName}`)} ${item}`,
        title: getTitle(categoryName, item),
        category: categoryName,
        icon: getIconForCategory(categoryName, item),
      });
    });
  });

  const filteredBodyCategoriesNames = Object.keys(semanticResponse.body_semantic_categories).filter((category) =>
    sourceSemanticCategories.includes(category)
  );

  filteredBodyCategoriesNames.forEach((categoryName) => {
    semanticResponse.body_semantic_categories[categoryName].forEach((item) => {
      blueTags.push({
        name: `${t(`offers_page.offers_list.${categoryName}`)} ${item}`,
        title: `${t(`offers_page.offers_list.${categoryName}`)} - ${item}`.replaceAll("_", " "),
      });
    });
  });

  window.addEventListener("storage", () => {
    setOnBoarding_step(sessionStorage.getItem("OnBoarding_step") == 1 ? 1 : 0);
  });
  let AllTagInt =
    (tripContinent?.length || 0) +
    (uniqueLocations?.length || 0) +
    (location?.departureCity?.length || 0) +
    (dateTexts?.length || 0) +
    (periodText ? 1 : 0) +
    (adultsText ? 1 : 0) +
    (childrenText ? 1 : 0) +
    (priceText ? 1 : 0) +
    whiteTags?.length +
    blueTags?.length;
  const intItemToDisply = 10;
  let itemsCount = intItemToDisply;

  const itemsCountCheck = (array, func2, blueTags = false) => {
    if (array?.length > 5) {
      AllTagInt -= array.length - 1;
      itemsCount -= 1;
      const addingButton = () => {
        return (
          <span
            key={"addingbuton"}
            style={{ color: blueTags ? "white" : "black", cursor: "pointer", width: "20px" }}
            onClick={() => setIsShowAll(!isShowAll)}
          >
            <ExpandMoreArrow></ExpandMoreArrow>
          </span>
        );
      };
      const res = React.cloneElement(func2(array[0], 0), null, [...func2(array[0], 0).props.children, addingButton()]);

      return res;
    }

    return array && array[0]
      ? array.reduce((acc, ele, index) => {
          if (itemsCount <= 0) {
            return acc;
          } else {
            itemsCount -= 1;
            acc.push(func2(ele, index));
            return acc;
          }
        }, [])
      : null;
  };

  const tripContinentElement = (continent, _index) => {
    return (
      <li className={styles.item} key={`continent-${continent}`}>
        <Location className={styles.propertyIcon} />
        <span>{continent}</span>
      </li>
    );
  };
  const uniqueLocationsElement = (location, _index) => {
    return (
      <li className={styles.item} key={`location-${location}`}>
        <Location className={styles.propertyIcon} />
        <span>{location}</span>
      </li>
    );
  };
  const locationDepartureCityElement = (departure_city, _index) => {
    return (
      <li className={styles.item} key={`departure_city-${departure_city}`}>
        <img src={departureIcon} className={styles.propertyIcon} />
        <span>{departure_city}</span>
      </li>
    );
  };
  const dateTextsElement = (text, index) => {
    return (
      <li key={index} className={styles.item}>
        <Calendar22 className={styles.propertyIcon} />
        <span>{text}</span>
      </li>
    );
  };
  const periodTextElement = (periodText, index) => {
    return (
      <li className={styles.item} key={`periodText-${index}`}>
        <Calendar22 className={styles.propertyIcon} />
        <span>{`${periodText} ${t("offers_page.offers_list.days")}`}</span>
      </li>
    );
  };
  const adultsTextElement = (adultsText, index) => {
    return (
      <li className={styles.item} key={`adultsText=${index}`}>
        <Man className={styles.propertyIcon} />
        <span>{`${t("offers_page.offers_list.adults")} - ${adultsText}`}</span>
      </li>
    );
  };
  const childrenTextElement = (childrenText, index) => {
    return (
      <li className={styles.item} key={`childrenText-${index}`}>
        <Children className={styles.propertyIcon} />
        <span>{`${t("offers_page.offers_list.children")} - ${childrenText}`}</span>
      </li>
    );
  };
  const priceTextElement = (priceText, index) => {
    return (
      <li className={styles.item} key={`priceText-${index}`}>
        <Price viewBox="0 0 17 24" />
        <span>{priceText}</span>
      </li>
    );
  };
  const whiteTagsElement = (tag, _index) => {
    return (
      <li className={styles.item} key={tag.name}>
        {tag.icon}
        <span>{tag.title}</span>
      </li>
    );
  };
  const blueTagsElement = (tag, _index) => {
    return (
      <li className={styles.item} key={tag.name}>
        <span>{tag.title}</span>
      </li>
    );
  };
  const showAllButtons = () => {
    return (
      <span>
        {isShowAll && (
          <span id="hide_all_buttons" onClick={() => setIsShowAll(!isShowAll)} className={styles.showMoreLessToggle}>
            {t("offers_page.filters.hide")}
          </span>
        )}
        {!isShowAll && (
          <span id="show_all_buttons" onClick={() => setIsShowAll(!isShowAll)} className={styles.showMoreLessToggle}>
            {t("offers_page.filters.view_all")}
          </span>
        )}
      </span>
    );
  };

  return (
    <div className={styles.onboarding_semantic} style={{ zIndex: 5000 * OnBoarding_step, important: true }}>
      <Tooltip disableFocusListener followCursor title={t("offers_page.offers_list.tooltip_list_of_tags")}>
        <Typography mt={2} color="#FFF" fontSize="18px" fontWeight="500">
          {t("offers_page.offers_list.deep_search_query_intent_understanding")}
          <Info className={styles.propertyIcon} />
        </Typography>
      </Tooltip>

      <Box mt={2}>
        <ul className={styles.filtersWrapper} id="search_semantic_tags">
          {tripContinent && isShowAll
            ? tripContinent.map((continent, index) => tripContinentElement(continent, index))
            : itemsCountCheck(tripContinent, tripContinentElement)}
          {uniqueLocations && isShowAll
            ? uniqueLocations.map((location, index) => uniqueLocationsElement(location, index))
            : itemsCountCheck(uniqueLocations, uniqueLocationsElement)}
          {location && location.departureCity && isShowAll
            ? location.departureCity.map((departure_city, index) => locationDepartureCityElement(departure_city, index))
            : itemsCountCheck(location.departureCity, locationDepartureCityElement)}
          {dateTexts.length > 0 && isShowAll
            ? dateTexts.map((text, index) => dateTextsElement(text, index))
            : itemsCountCheck(dateTexts, dateTextsElement)}
          {periodText && isShowAll
            ? periodTextElement(periodText, null)
            : itemsCountCheck([periodText], periodTextElement)}
          {adultsText && isShowAll
            ? adultsTextElement(adultsText, null)
            : itemsCountCheck([adultsText], adultsTextElement)}
          {childrenText && isShowAll
            ? childrenTextElement(childrenText, null)
            : itemsCountCheck([childrenText], childrenTextElement)}
          {priceText && isShowAll ? priceTextElement(priceText, null) : itemsCountCheck([priceText], priceTextElement)}
          {isShowAll
            ? whiteTags.map((tag) => whiteTagsElement(tag, null))
            : itemsCountCheck(whiteTags, whiteTagsElement)}
          {AllTagInt > intItemToDisply &&
            AllTagInt - blueTags.length >= intItemToDisply &&
            (!isShowAll || blueTags.length === 0) && <div style={{ marginTop: "6px" }}>{showAllButtons()}</div>}
        </ul>
        <ul className={styles.semanticModelsWrapper} id="search_semantic_tags_second">
          {isShowAll
            ? blueTags.map((tag) => blueTagsElement(tag, null))
            : itemsCountCheck(blueTags, blueTagsElement, true)}
          {AllTagInt > intItemToDisply &&
            (AllTagInt - blueTags.length < intItemToDisply || isShowAll) &&
            blueTags.length > 0 &&
            showAllButtons()}
        </ul>
      </Box>
    </div>
  );
};

export default SearchSemanticModel;
